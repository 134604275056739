import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className, children }) => (
    <StaticQuery 
        query={graphql`
            query {
                paperBackground: file(relativePath: {eq: "paper-texture.jpg"}) {
                childImageSharp {
                    fluid(quality: 80, maxWidth: 2500) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
        `}
        render={data => {
            const imageData = data.paperBackground.childImageSharp.fluid
            return (
                <BackgroundImage
                    tag="section"
                    className={className}
                    fluid={imageData}
                    backgroundColor={`white`}
                    style={{height: 'auto'}}
                >
                {children}
                </BackgroundImage>
            )
        }}
    />
)

const StyledBackgroundSection = styled(BackgroundSection)`
    width: 100%;
    background-position: top center;
    background-repeat: repeat-y;
    background-size: cover
`

export default StyledBackgroundSection